
import cisco from '../elements/cisco.png';
import zepto from '../elements/zepto.png';
import bsc from '../elements/bsc.jpg';
import simplitask1 from '../elements/simplitask1.jpg';
import stift1 from '../elements/stift1.png';
import tech from '../elements/tech.png'
import { AiOutlineLinkedin, AiOutlineGithub, AiOutlineMail, AiOutlineCopyright } from 'react-icons/ai'
import Experience from '../components/Experience';
import { useEffect } from 'react';

function Landing(props) {

    useEffect(() => {
        props.setShowName(false);
    }, []);

    return (
        <div>
            <div className='view-one'>
                <div className="title">
                    <div className='name-header'>Hi There! I'm Garvit Sadhwani</div>
                    <div className='name-sub-header'>A software developer with a creative touch, loving what I do</div>
                </div>
                <div className='flex-center'>
                    <img className='tech-img' src={tech} alt='filler' />
                </div>
            </div>
            <div className='view-two'>
                <Experience
                    white_text={true}
                    title="What I'm upto"
                    desc="Currently I'm working as a Backend Engineer with Zepto. My work revolves around implementing features and support for the Growth and User retention team"
                    golang={true} python={true}
                    main_image={zepto}
                />

                <div className='project-container'>
                    <div className='project-title'>Recent Projects</div>
                    <div style={{ marginTop: '-3svh' }}>
                        <Experience
                            project={true}
                            title="Stift, BSE stock screener"
                            link="https://stift.gsadhwani.com"
                            desc="Developed a dynamic stock screening application tailored specifically for Bombay Stock Exchange (BSE) stocks. 
                            This platform simplifies the stock selection process by running customized strategies on BSE's daywise data. With robust security measures, including Google authentication, all information remains protected at all times."
                            react={true} node={true} postgresql={true}
                            main_image={stift1}
                        />
                    </div>
                    <div style={{ marginBottom: '5svh' }}>
                        <Experience
                            project={true}
                            title="Task management WebApp"
                            desc="Developed a full stack web app with Golang, connected with a PostgreSQL database. Implemented signup and login functionalities with user authentication for each account
                                The tasks were displayed ordered by time mentioned by the user and were added or deleted dynamically"
                            golang={true} postgresql={true}
                            main_image={simplitask1}
                        />
                    </div>
                    {/* <div style={{ marginBottom: '5svh' }}>
                        <Experience
                            project={true}
                            title="Hand gesture sensing"
                            desc="Developed a console application with C++ and OpenCV library to automate simple tasks on a device. 
                            Designed a user-friendly interface with adjustable settings for calibration and preferences and 
                            implemented functionalities like volume variation, screen capture, and PC restart using simple gestures"
                            cpp={true} opencv={true}
                            video={true}
                        />
                    </div> */}


                </div>
            </div>
            <div className='view-three'>
                <div className='project-title' style={window.innerWidth < 800 ? { color: 'black', marginTop: '22svh' } : { color: 'black' }}>Other Experiences</div>
                <div style={{ marginTop: '-7svh' }}>
                    <Experience
                        title="Software Engineer"
                        desc="Worked with Cisco on implementing end to end features on WiFi Lens: a full stack web app.
                        The application took in log files generated by wireless controllers; extracting and displaying valuable insights. Along with this worked in feature development and support for client connections to scale WLANs."
                    react={true} node={true} mongo={true} python={true} c={true}
                    main_image={cisco}
                    />
                </div>

                <div>
                    <Experience
                        title="Software Engineering intern"
                        desc="Designed a system to authenticate devices via UDP before allowing connection to the WLAN deployment
                    Developed an algorithm to retrieve software image hashes and verify them against corresponding versions
                    Developed an algorithm to verify the signature of hardware via Trust Anchor Module and Linux APIs
                    Impacted the enterprise-level customer base by providing better security across the network"
                        c={true}
                        main_image={cisco}
                    />
                </div>
                
                <div style={{ marginLeft: '5svw' }}>
                    <Experience
                        project={true}
                        title="Internet of Things intern"
                        desc="Developed smart wristbands for enclosed premises to help with social distancing during COVID-19
                        Worked on the client-side of the band to implement all functions and communications with the server
                        Developed a counting system to keep a check on the total number of people inside the premises
                        Designed and implemented an 88% accurate distance calculation system without any sensors"
                        c={true}
                        main_image={bsc}
                    />
                </div>
                <div className='footer'>
                    <div className='footer-text-main'>Let's get in touch!</div>
                    <div>
                        <a target='_blank' className='contact-icon' href='https://www.linkedin.com/in/garvit-sadhwani-8a76b016b/' rel='noreferrer'><AiOutlineLinkedin size={'1.2rem'} /></a>
                        <a target='_blank' className='contact-icon' href='https://github.com/GarvitSadhwani' rel='noreferrer'><AiOutlineGithub size={'1.2rem'} /></a>
                        <a target='_blank' className='contact-icon' href='mailto:garvit.sadh@gmail.com' rel='noreferrer'><AiOutlineMail size={'1.2rem'} /></a>
                    </div>
                    <div className='footer-text'><AiOutlineCopyright size={'0.6rem'} /> Garvit Sadhwani 2023</div>

                </div>
            </div>
        </div>
    );
}

export default Landing;