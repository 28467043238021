import React, { useState } from 'react'

function Navbar(props){
    const [navbarLight,setNavbarLight]=useState(false);
    
    window.addEventListener('scroll',()=>{
      const scrollPositionVH = (window.scrollY / window.innerHeight) * 100;
      let limitL=window.innerWidth<800?75:96;
      let limitH=window.innerWidth<800?125:155;
      let limiLNav=window.innerWidth<800?25:30;
      if(!props.showName && scrollPositionVH > limiLNav) { 
        props.setShowName(true);
      } else if(props.showName && scrollPositionVH < limiLNav){
        props.setShowName(false);
      }

      if(props.showName && scrollPositionVH > limitL && scrollPositionVH < limitH) { 
        setNavbarLight(true);
      } else {
        setNavbarLight(false);
      }

    });


    const homeHandler=()=>{
      props.setShowName(false);
    }

    const aboutHandler=()=>{
      props.setShowName(true);
    }

    return(
        <div className='navbar-backdrop'>
            <div className="navbar">
                <div className={`navbar-name ${props.showName ? 'navbar-visible' : ''}`}><a onClick={homeHandler} style={navbarLight?{color:'white'}:{color:'black'}} href="/#/">Garvit Sadhwani</a></div>
                <div className={`${navbarLight?'navbar-element-light':'navbar-element'}`}> <a style={navbarLight?{color:'white'}:{color:'black'}} target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1mIk629lBcI9WnCtwZJTxtH7Kn30YN6J_/view?usp=sharing">Resume </a></div>
                <div className={`${navbarLight?'navbar-element-light':'navbar-element'}`}> <a onClick={aboutHandler} style={navbarLight?{color:'white'}:{color:'black'}} href="/#/about">About </a> </div>
            </div>
        </div>
        
    );
}

export default Navbar;