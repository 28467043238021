import './App.css';
import Navbar from './navbar/Navbar';
import React,{useState} from 'react'
import {Routes, HashRouter,Route} from "react-router-dom"

import About from './pages/About';
import Landing from './pages/Landing';
import Notfound from './pages/Notfound'

function App() {
  const [showName,setShowName]=useState(false);

  return (
    <HashRouter>
        <Navbar showName={showName} setShowName={setShowName}/>
        <Routes>
            <Route path='/' element={<Landing setShowName={setShowName}/>}/>
            <Route path='/about' element={<About setShowName={setShowName}/>}/>
            <Route path='/*' element={<Notfound/>}/>
        </Routes>
    </HashRouter>
  );
}

export default App;
